/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import ReactFlow, {
    ReactFlowProvider,
    addEdge,
    MiniMap,
    Controls,
    Background,
    useNodesState,
    useEdgesState,
} from 'reactflow';
import 'reactflow/dist/style.css';
import ELK from 'elkjs/lib/elk.bundled.js';
import { InputNode, SumNodePlusMinusNull, SumNodePlusPlusPlus, SumNodePlusPlusMinus, SumNodePlusMinusMinus, SumNodePlusMinusPlus, SumNodePlusPlusNull, SumNodePlusNullMinus, SumNodePlusNullPlus, PIDNode, PlantNode, ScopeNode, GainNode, TFNodeRight, TFNodeLeft } from './CustomNodes';
import axios from 'axios';
import { AppBar, Toolbar, Box, Button, Container, Grid, Card, CardContent, Typography, Link, CircularProgress, IconButton } from '@mui/material';
import { FaPlusCircle, FaDesktop, FaPlay } from 'react-icons/fa';
import { LuThermometerSnowflake } from 'react-icons/lu';
import { Bs1SquareFill, BsFillArrowRightSquareFill, BsFillArrowLeftSquareFill, BsFiletypeJson } from 'react-icons/bs';
import { TbWaveSine, TbFunctionFilled } from 'react-icons/tb';
import EscalatorIcon from '@mui/icons-material/Escalator';
import './DiagramDecoder.css';
import { keyframes } from "@emotion/react";
import { Link as RouterLink } from "react-router-dom";
import JSZip from 'jszip';
import { Helmet } from "react-helmet";
import FeedbackControlBlogPosts from "../HowTosFeedbackControl/FeedbackControlBlogPosts";
import SumPlusMinusIcon from './sum-gray-v3.png';
import StepInputIcon from './step-input-gray-v2.png';
import TFRightIcon from './transfer-function-right-gray-v2.png';
import TFLeftIcon from './transfer-function-left-gray-v2.png';
import ScopeIcon from './scope-gray-v4.png';

const initialNodes = [];
const initialEdges = [];

const nodeTypes = {
    input: InputNode,
    sumPlusMinusNull: SumNodePlusMinusNull,
    sumPlusPlusNull: SumNodePlusPlusNull,
    sumPlusPlusPlus: SumNodePlusPlusPlus,
    sumPlusPlusMinus: SumNodePlusPlusMinus,
    sumPlusMinusPlus: SumNodePlusMinusPlus,
    sumPlusMinusMinus: SumNodePlusMinusMinus,
    sumPlusNullMinus: SumNodePlusNullMinus,
    sumPlusNullPlus: SumNodePlusNullPlus,
    pid: PIDNode,
    plant: PlantNode,
    scope: ScopeNode,
    gain: GainNode,
    tfRight: TFNodeRight,
    tfLeft: TFNodeLeft,
};

const rainbowAnimation = keyframes`
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
`;

const pulseAnimation = keyframes`
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
`;

const PricingBox = () => (
    <Grid>
        <Box
            sx={{
                marginTop: '30px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Card
                sx={{
                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                    backgroundColor: '#353535',
                    borderRadius: '15px',
                    border: '2px solid #35353500',
                    width: '100%',
                }}
            >
                <CardContent>
                    <Typography
                        variant="h6"
                        sx={{
                            textAlign: 'center',
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 'bold',
                            letterSpacing: '0.05em',
                            background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            animation: `${rainbowAnimation} 10s ease infinite`,
                            backgroundSize: '200% 200%',
                            '@media (max-width:600px)': {
                                fontSize: '1.2em',
                            },
                            marginBottom: '10px',
                        }}
                    >
                        Pricing
                    </Typography>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography
                            variant="h4"
                            sx={{ color: '#9DC183', marginBottom: '20px' }}
                        >
                            $0.99 / month
                        </Typography>
                        <Box sx={{ textAlign: 'left', margin: '0 auto', maxWidth: '400px' }}>
                            {[
                                'Full access to all the apps',
                                'Free articles and tutorials',
                                'No need to install anything',
                                'Auth0 Authentication',
                                'Stripe Payments Integration'
                            ].map((item) => (
                                <Typography
                                    key={item}
                                    variant="h6"
                                    sx={{ color: '#b9b9b9', marginBottom: '5px', display: 'flex', alignItems: 'center' }}
                                >
                                    <i className="fas fa-check-circle" style={{ marginRight: '10px', color: '#9DC183' }}></i>
                                    {item}
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Box>
        <Box sx={{ mt: 3, textAlign: 'center' }}>
            <Card sx={{ boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)', borderRadius: '15px', backgroundColor: '#353535' }}>
                <CardContent>
                    <Typography variant="h5" gutterBottom sx={{ color: 'white' }}>
                        Get NebulaSolver
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2, color: '#b9b9b9' }}>
                        Subscribe to access the PID Control System Tool.
                    </Typography>
                    <Button
                        variant="contained"
                        component={RouterLink}
                        to="/subscribe"
                        sx={{
                            backgroundColor: '#15a2fa',
                            '&:hover': {
                                backgroundColor: '#9DC183',
                            },
                            width: '50%',
                            display: 'block',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            animation: `${pulseAnimation} 2s infinite`,
                        }}
                    >
                        Go to Subscribe
                    </Button>
                </CardContent>
            </Card>
        </Box>
    </Grid>
);

const hoverAnimation = keyframes`
    0% { color: #fffffe; }
    100% { color: #9DC183; }
`;

const MoreByNebulaSolver = () => (
    <Box sx={{ marginTop: '0px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '14px' }}>
            <Card sx={{ boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)', backgroundColor: '#353535', borderRadius: '15px', border: '2px solid #35353500', width: '100%', mt: 2 }}>
                <CardContent sx={{ padding: '14px 0px' }}>
                    <Typography variant="h6" sx={{ textAlign: 'center', fontFamily: "'Poppins', sans-serif", fontWeight: 'bold', letterSpacing: '0.05em', background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', animation: `${rainbowAnimation} 10s ease infinite`, backgroundSize: '200% 200%', '@media (max-width:600px)': { fontSize: '1.2em' }, marginBottom: '10px' }}>
                        More by NebulaSolver
                    </Typography>
                    <Box sx={{ textAlign: 'left', margin: '0 30px', maxWidth: '400px' }}>
                        {[
                            { href: "https://nebulasolver.com/equations", text: "Equation Solver" },
                            { href: "https://nebulasolver.com/matrix", text: "Linear Algebra" },
                            { href: "https://nebulasolver.com/controlsystems", text: "Control Systems" },
                            { href: "https://nebulasolver.com/finiteelements", text: "Finite Elements" },
                            { href: "https://nebulasolver.com/thermodynamics", text: "Thermodynamics" },
                            { href: "https://nebulasolver.com/batterymanagement", text: "Battery Management" },
                            { href: "https://nebulasolver.com/electronics", text: "Electronics" },
                            { href: "https://nebulasolver.com/systems", text: "Systems Engineering" },
                            { href: "https://nebulasolver.com/diagrams", text: "Diagrams" },
                            { href: "https://nebulasolver.com/gantt", text: "Gantt Chart" },
                            { href: "https://nebulasolver.com/stocks", text: "Stock Analysis" }
                        ].map(item => (
                            <Link
                                key={item.href}
                                href={item.href}
                                sx={{
                                    display: 'block',
                                    mb: 1,
                                    color: '#fffffe',
                                    textDecoration: 'none',
                                    '&:hover': {
                                        animation: `${hoverAnimation} 0.3s forwards`
                                    }
                                }}
                            >
                                <i className="fas fa-angle-right" style={{ marginRight: '10px', color: '#9DC183' }}></i>
                                {item.text}
                            </Link>
                        ))}
                    </Box>
                </CardContent>
            </Card>
        </Box>
    </Box>
);

const FeedbackControl = () => {
    const { user, isAuthenticated, isLoading: authLoading } = useAuth0();
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const [elk] = useState(new ELK());
    const [jsonOutput, setJsonOutput] = useState("");
    const [error, setError] = useState('');
    const [subscribeStatus, setSubscribeStatus] = useState(false);
    const [isSubscriptionStatusLoading, setIsSubscriptionStatusLoading] = useState(true);
    const [closedLoopTF, setClosedLoopTF] = useState('');
    const [stepResponseImage, setStepResponseImage] = useState(null);

    useEffect(() => {
        const fetchSubscriptionStatus = async () => {
            setIsSubscriptionStatusLoading(true);
            if (isAuthenticated && user && !authLoading) {
                try {
                    const response = await fetch('/.netlify/functions/getUserSubscriptionStatus', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ userEmail: user.email }),
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error ${response.status}`);
                    }

                    const subscriptionData = await response.json();
                    setSubscribeStatus(subscriptionData.isSubscribed);
                } catch (error) {
                    console.error('Error fetching subscription status:', error);
                } finally {
                    setIsSubscriptionStatusLoading(false);
                }
            } else {
                setIsSubscriptionStatusLoading(false);
            }
        };

        fetchSubscriptionStatus();
    }, [user, isAuthenticated, authLoading]);

    const handleAnalysis = async () => {
        if (!isAuthenticated || !user) {
            setError('User not authenticated');
            return;
        }

        try {
            const jwtResponse = await fetch('/.netlify/functions/generateJWTFeedbackControl', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userEmail: user.email }),
            });

            if (!jwtResponse.ok) {
                throw new Error(`HTTP error ${jwtResponse.status}: ${jwtResponse.statusText}`);
            }

            const jwtData = await jwtResponse.json();
            const jwtToken = jwtData.token;

            const writeResponse = await fetch('/.netlify/functions/writeDiagram', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwtToken}`
                },
                body: JSON.stringify({ nodes, edges }),
            });

            if (!writeResponse.ok) {
                throw new Error(`HTTP error! status: ${writeResponse.status}`);
            }

            const { diagramId } = await writeResponse.json();

            const analysisResponse = await fetch(`https://gpfz3t4djr.us-west-2.awsapprunner.com/feedback`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwtToken}`
                },
                body: JSON.stringify({ diagramId }),
            });

            if (!analysisResponse.ok) {
                throw new Error(`HTTP error! status: ${analysisResponse.status}`);
            }

            const analysisData = await analysisResponse.json();
            setClosedLoopTF(analysisData.closed_loop_transfer_function);

            // Open new tab and display transfer function
            const newTab = window.open();
            newTab.document.open();
            newTab.document.write(`<pre>${analysisData.closed_loop_transfer_function}</pre>`);
            newTab.document.close();

        } catch (err) {
            setError(`Error during the analysis operation: ${err.message}`);
            console.error(err);
        }
    };

    const handleStepResponse = async () => {
        if (!isAuthenticated || !user) {
            setError('User not authenticated');
            return;
        }

        try {
            const jwtResponse = await fetch('/.netlify/functions/generateJWTFeedbackControl', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userEmail: user.email }),
            });

            if (!jwtResponse.ok) {
                throw new Error(`HTTP error ${jwtResponse.status}: ${jwtResponse.statusText}`);
            }

            const jwtData = await jwtResponse.json();
            const jwtToken = jwtData.token;

            const writeResponse = await fetch('/.netlify/functions/writeDiagram', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwtToken}`
                },
                body: JSON.stringify({ nodes, edges }),
            });

            if (!writeResponse.ok) {
                throw new Error(`HTTP error! status: ${writeResponse.status}`);
            }

            const { diagramId } = await writeResponse.json();

            const stepResponseResponse = await fetch(`https://gpfz3t4djr.us-west-2.awsapprunner.com/step_response`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwtToken}`
                },
                body: JSON.stringify({ diagramId }),
            });

            if (!stepResponseResponse.ok) {
                throw new Error(`HTTP error! status: ${stepResponseResponse.status}`);
            }

            const zipBlob = await stepResponseResponse.blob();
            const zip = await JSZip.loadAsync(zipBlob);
            const stepResponseImageBlob = await zip.file("step_response_plot.png").async("blob");
            const imageObjectURL = URL.createObjectURL(stepResponseImageBlob);
            setStepResponseImage(imageObjectURL);

        } catch (err) {
            setError(`Error during the step response operation: ${err.message}`);
            console.error(err);
        }
    };

    const onConnect = useCallback((params) => setEdges((eds) => addEdge({ ...params, type: 'smoothstep' }, eds)), []);

    const onDrop = useCallback(
        (event) => {
            event.preventDefault();
            const reactFlowBounds = event.target.getBoundingClientRect();
            const type = event.dataTransfer.getData('application/reactflow');
            if (typeof type === 'undefined' || !type) return;

            const position = {
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top,
            };
            const newNode = {
                id: `${+new Date()}`,
                type,
                position,
                data: { label: `${type} node` },
                style: { border: '2px solid #b9b9b9' }
            };
            setNodes((nds) => nds.concat(newNode));
        },
        [setNodes]
    );

    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const generateJson = useCallback(() => {
        const graph = {
            id: "root",
            layoutOptions: {
                'elk.algorithm': 'layered',
            },
            children: nodes.map(node => ({
                id: node.id,
                width: 100,
                height: 50,
                label: node.data.label,
                data: node.data,
            })),
            edges: edges.map(edge => ({
                id: edge.id,
                sources: [edge.source],
                targets: [edge.target],
            })),
        };

        elk.layout(graph)
            .then((layout) => {
                const jsonOutput = JSON.stringify(layout, null, 2);
                const newTab = window.open();
                newTab.document.open();
                newTab.document.write(`<pre>${jsonOutput}</pre>`);
                newTab.document.close();
            })
            .catch(console.error);
    }, [nodes, edges, elk]);

    const writeDiagram = async () => {
        try {
            const response = await axios.post('/.netlify/functions/writeDiagram', { nodes, edges });
            console.log('Diagram saved:', response.data);
        } catch (error) {
            console.error('Error saving diagram:', error);
        }
    };

    if (authLoading || isSubscriptionStatusLoading) {
        return (
            <Container maxWidth="xs" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
            </Container>
        );
    }

    if (!subscribeStatus) {
        return (
            <Container maxWidth={false}>
                <Grid container spacing={3} alignItems="flex-start" justifyContent="center">
                    <Grid item xs={12} md={3}>
                        <Box sx={{ marginTop: '0px' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '14px' }}>
                                <Card sx={{ boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)', backgroundColor: '#353535', borderRadius: '15px', border: '2px solid #35353500', width: '100%', mt: 2 }}>
                                    <CardContent sx={{ padding: '0px 0px' }}>
                                        <FeedbackControlBlogPosts />
                                    </CardContent>
                                </Card>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <PricingBox />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <MoreByNebulaSolver />
                    </Grid>
                </Grid>
                <Helmet>
                    <title>NebulaSolver - Feedback Control</title>
                    <link rel="canonical" href={`https://nebulasolver.com/feedbackcontrol`} />
                    <meta property="og:title" content="NebulaSolver - Feedback Control" />
                    <meta property="og:type" content="website" />
                    <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png" />
                    <meta property="og:url" content="https://nebulasolver.com/feedbackcontrol" />
                    <meta property="og:description" content="NebulaSolver - Cloud-Based Feedback Control" />
                    <link rel="alternate" hrefLang="en" href={`https://nebulasolver.com/feedbackcontrol`} />
                    <link rel="alternate" hrefLang="x-default" href={`https://nebulasolver.com/feedbackcontrol`} />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="NebulaSolver - Feedback Control" />
                    <meta name="twitter:description" content="NebulaSolver - Cloud-Based Feedback Control" />
                    <meta name="twitter:image" content="%PUBLIC_URL%/apple-touch-icon.png" />
                    <meta name="twitter:site" content="@nebulasolver" />
                </Helmet>
            </Container>
        );
    }

    const SidebarButtons = () => {
        const onDragStart = (event, nodeType) => {
            event.dataTransfer.setData('application/reactflow', nodeType);
            event.dataTransfer.effectAllowed = 'move';
        };

        const buttonIcons = [
            { type: 'input', icon: StepInputIcon },
            { type: 'sumPlusMinusNull', icon: SumPlusMinusIcon },
            { type: 'tfRight', icon: TFRightIcon },
            { type: 'tfLeft', icon: TFLeftIcon },
            { type: 'scope', icon: ScopeIcon },
        ];

        return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                {buttonIcons.map(({ type, icon }) => (
                    <IconButton
                        key={type}
                        onDragStart={(event) => onDragStart(event, type)}
                        draggable
                        sx={{ color: '#ffc801', mt: 1 }}
                    >
                        <img src={icon} alt={`${type} icon`} style={{ width: '48px', height: '48px', objectFit: 'contain' }} />
                    </IconButton>
                ))}
            </Box>
        );
    };

    return (
        <Container maxWidth={false}>
            <ReactFlowProvider>
                <AppBar position="relative" sx={{ backgroundColor: '#353535', boxShadow: 'none', padding: '10px 0' }}>
                    <Toolbar sx={{ justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', gap: '20px', marginBottom: '10px', mt: 1 }}>
                            <Button
                                onClick={generateJson}
                                sx={{ color: '#ffc801', marginRight: '10px' }}
                                startIcon={<BsFiletypeJson />}
                            >
                                JSON
                            </Button>
                            <Button
                                onClick={handleAnalysis}
                                sx={{ color: '#ffc801' }}
                                startIcon={<TbFunctionFilled />}
                            >
                                T-Func
                            </Button>
                            <Button
                                onClick={handleStepResponse}
                                sx={{ color: '#ffc801' }}
                                startIcon={<FaPlay />}
                            >
                                Analyze
                            </Button>
                        </Box>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>
                            <SidebarButtons />
                        </Box>
                    </Toolbar>
                </AppBar>
                <Grid container spacing={3} alignItems="stretch" justifyContent="center" sx={{ mt: 2 }}>
                    <Grid item xs={12} md={1}>
                        <Box sx={{ width: '100%', marginTop: '30px', height: '100%', backgroundColor: '#2a2a2a' }}>
                            {/* Placeholder content */}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <Box sx={{ width: '100%', marginTop: '30px', height: '600px' }}>
                            <ReactFlow
                                nodes={nodes}
                                edges={edges}
                                onNodesChange={onNodesChange}
                                onEdgesChange={onEdgesChange}
                                onConnect={onConnect}
                                onDrop={onDrop}
                                onDragOver={onDragOver}
                                fitView
                                nodeTypes={nodeTypes}
                            >
                                <MiniMap />
                                <Controls />
                                <Background />
                            </ReactFlow>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={1}>
                        <Box sx={{ width: '100%', marginTop: '30px', height: '100%', backgroundColor: '#2a2a2a' }}>
                            {/* Placeholder content */}
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="stretch" justifyContent="center" sx={{ flex: 1 }}>
                    <Grid item xs={12} md={1}>
                        <Box sx={{ width: '100%', marginTop: '30px', height: '100%', backgroundColor: '#2a2a2a' }}>
                            {/* Placeholder content */}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <Box sx={{ width: '100%', marginTop: '30px', textAlign: 'center', backgroundColor: '#2a2a2a' }}>
                            {stepResponseImage && <img src={stepResponseImage} alt="Step Response" style={{ maxWidth: '100%' }} />}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={1}>
                        <Box sx={{ width: '100%', marginTop: '30px', height: '100%', backgroundColor: '#2a2a2a' }}>
                            {/* Placeholder content */}
                        </Box>
                    </Grid>
                </Grid>
            </ReactFlowProvider>
            <Helmet>
                <title>NebulaSolver - Feedback Control</title>
                <link rel="canonical" href={`https://nebulasolver.com/feedbackcontrol`} />
                <meta property="og:title" content="NebulaSolver - Feedback Control" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta property="og:url" content="https://nebulasolver.com/feedbackcontrol" />
                <meta property="og:description" content="NebulaSolver - Cloud-Based Feedback Control" />
                <link rel="alternate" hrefLang="en" href={`https://nebulasolver.com/feedbackcontrol`} />
                <link rel="alternate" hrefLang="x-default" href={`https://nebulasolver.com/feedbackcontrol`} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="NebulaSolver - Feedback Control" />
                <meta name="twitter:description" content="NebulaSolver - Cloud-Based Feedback Control" />
                <meta name="twitter:image" content="%PUBLIC_URL%/apple-touch-icon.png" />
                <meta name="twitter:site" content="@nebulasolver" />
            </Helmet>
        </Container>
    );
};

export default FeedbackControl;
