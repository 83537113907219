/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Box,
    Container,
    TextField,
    Button,
    Typography,
    Card,
    CardContent,
    Grid,
    CircularProgress,
    CardMedia,
    Link
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { keyframes } from '@emotion/react';
import { Link as RouterLink } from 'react-router-dom';
import JSZip from 'jszip';
import StocksBlogPosts from "../components/HowTosStocks/StocksBlogPosts";
import { Helmet } from "react-helmet";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const rainbowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const PricingBox = () => (
    <Grid>
        <Box
            sx={{
                marginTop: '30px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Card
                sx={{
                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                    backgroundColor: '#353535',
                    borderRadius: '15px',
                    border: '2px solid #35353500',
                    width: '100%',
                }}
            >
                <CardContent>
                    <Typography
                        variant="h6"
                        sx={{
                            textAlign: 'center',
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 'bold',
                            letterSpacing: '0.05em',
                            background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            animation: `${rainbowAnimation} 10s ease infinite`,
                            backgroundSize: '200% 200%',
                            '@media (max-width:600px)': {
                                fontSize: '1.2em',
                            },
                            marginBottom: '10px',
                        }}
                    >
                        Pricing
                    </Typography>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography
                            variant="h4"
                            sx={{ color: '#9DC183', marginBottom: '20px' }}
                        >
                            $0.99 / month
                        </Typography>
                        <Box sx={{ textAlign: 'left', margin: '0 auto', maxWidth: '400px' }}>
                            {[
                                'Full access to all the apps',
                                'Free articles and tutorials',
                                'No need to install anything',
                                'Auth0 Authentication',
                                'Stripe Payments Integration'
                            ].map((item) => (
                                <Typography
                                    key={item}
                                    variant="h6"
                                    sx={{ color: '#b9b9b9', marginBottom: '5px', display: 'flex', alignItems: 'center' }}
                                >
                                    <i className="fas fa-check-circle" style={{ marginRight: '10px', color: '#9DC183' }}></i>
                                    {item}
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Box>
        <Box sx={{ mt: 3, textAlign: 'center' }}>
            <Card sx={{ boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)', borderRadius: '15px', backgroundColor: '#353535' }}>
                <CardContent>
                    <Typography variant="h5" gutterBottom sx={{ color: 'white' }}>
                        Get NebulaSolver
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2, color: '#b9b9b9' }}>
                        Subscribe to access the Stocks Analysis Tool.
                    </Typography>
                    <Button
                        variant="contained"
                        component={RouterLink}
                        to="/subscribe"
                        sx={{
                            backgroundColor: '#15a2fa',
                            '&:hover': {
                                backgroundColor: '#9DC183',
                            },
                            width: '50%',
                            display: 'block',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            animation: `${pulseAnimation} 2s infinite`,
                        }}
                    >
                        Go to Subscribe
                    </Button>
                </CardContent>
            </Card>
        </Box>
    </Grid>
);

const hoverAnimation = keyframes`
  0% { color: #fffffe; }
  100% { color: #9DC183; }
`;

const MoreByNebulaSolver = () => (
    <Box sx={{ marginTop: '0px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '14px' }}>
            <Card sx={{ boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)', backgroundColor: '#353535', borderRadius: '15px', border: '2px solid #35353500', width: '100%', mt: 2 }}>
                <CardContent sx={{ padding: '14px 0px' }}>
                    <Typography variant="h6" sx={{ textAlign: 'center', fontFamily: "'Poppins', sans-serif", fontWeight: 'bold', letterSpacing: '0.05em', background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', animation: `${rainbowAnimation} 10s ease infinite`, backgroundSize: '200% 200%', '@media (max-width:600px)': { fontSize: '1.2em' }, marginBottom: '10px' }}>
                        More by NebulaSolver
                    </Typography>
                    <Box sx={{ textAlign: 'left', margin: '0 30px', maxWidth: '400px' }}>
                        {[
                            { href: "https://nebulasolver.com/equations", text: "Equation Solver" },
                            { href: "https://nebulasolver.com/matrix", text: "Linear Algebra" },
                            { href: "https://nebulasolver.com/controlsystems", text: "Control Systems" },
                            { href: "https://nebulasolver.com/finiteelements", text: "Finite Elements" },
                            { href: "https://nebulasolver.com/thermodynamics", text: "Thermodynamics" },
                            { href: "https://nebulasolver.com/batterymanagement", text: "Battery Management" },
                            { href: "https://nebulasolver.com/electronics", text: "Electronics" },
                            { href: "https://nebulasolver.com/systems", text: "Systems Engineering" },
                            { href: "https://nebulasolver.com/diagrams", text: "Diagrams" },
                            { href: "https://nebulasolver.com/gantt", text: "Gantt Chart" },
                            { href: "https://nebulasolver.com/stocks", text: "Stock Analysis" }
                        ].map(item => (
                            <Link
                                key={item.href}
                                href={item.href}
                                sx={{
                                    display: 'block',
                                    mb: 1,
                                    color: '#fffffe',
                                    textDecoration: 'none',
                                    '&:hover': {
                                        animation: `${hoverAnimation} 0.3s forwards`
                                    }
                                }}
                            >
                                <i className="fas fa-angle-right" style={{ marginRight: '10px', color: '#9DC183' }}></i>
                                {item.text}
                            </Link>
                        ))}
                    </Box>
                </CardContent>
            </Card>
        </Box>
    </Box>
);

const Stocks = () => {
    const { user, isAuthenticated, isLoading: authLoading } = useAuth0();
    const [ticker, setTicker] = useState('');
    const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month'));
    const [endDate, setEndDate] = useState(dayjs());
    const [marketCapUrl, setMarketCapUrl] = useState(null);
    const [movingAverageUrl, setMovingAverageUrl] = useState(null);
    const [volatilityUrl, setVolatilityUrl] = useState(null);
    const [stockPriceUrl, setStockPriceUrl] = useState(null);
    const [tradingVolumeUrl, setTradingVolumeUrl] = useState(null);
    const [bollingerBandsUrl, setBollingerBandsUrl] = useState(null);
    const [rsiUrl, setRsiUrl] = useState(null);
    const [error, setError] = useState('');
    const [subscribeStatus, setSubscribeStatus] = useState(false);
    const [isSubscriptionStatusLoading, setIsSubscriptionStatusLoading] = useState(true);

    useEffect(() => {
        const fetchSubscriptionStatus = async () => {
            setIsSubscriptionStatusLoading(true);
            if (isAuthenticated && user && !authLoading) {
                try {
                    const response = await fetch('/.netlify/functions/getUserSubscriptionStatus', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ userEmail: user.email }),
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error ${response.status}`);
                    }

                    const subscriptionData = await response.json();
                    setSubscribeStatus(subscriptionData.isSubscribed);
                } catch (error) {
                    console.error('Error fetching subscription status:', error);
                } finally {
                    setIsSubscriptionStatusLoading(false);
                }
            } else {
                setIsSubscriptionStatusLoading(false);
            }
        };

        fetchSubscriptionStatus();
    }, [user, isAuthenticated, authLoading]);

    const handleAnalysis = async () => {
        if (!isAuthenticated || !user) {
            setError('User not authenticated');
            return;
        }

        try {
            const jwtResponse = await fetch('/.netlify/functions/generateJWTStocks', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userEmail: user.email }),
            });

            if (!jwtResponse.ok) {
                throw new Error(`HTTP error ${jwtResponse.status}: ${jwtResponse.statusText}`);
            }

            const jwtData = await jwtResponse.json();
            const jwtToken = jwtData.token;
            const payload = { ticker, startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD') };

            const response = await fetch('https://i66hhspaa6.us-west-2.awsapprunner.com/plot_stock', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwtToken}`
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const blob = await response.blob();
            const zip = await JSZip.loadAsync(blob);
            const marketCapBlob = await zip.file("market_cap.png").async("blob");
            setMarketCapUrl(URL.createObjectURL(marketCapBlob));
            const movingAverageBlob = await zip.file("moving_average.png").async("blob");
            setMovingAverageUrl(URL.createObjectURL(movingAverageBlob));
            const volatilityBlob = await zip.file("volatility.png").async("blob");
            setVolatilityUrl(URL.createObjectURL(volatilityBlob));
            const stockPriceBlob = await zip.file("stock_price.png").async("blob");
            setStockPriceUrl(URL.createObjectURL(stockPriceBlob));
            const tradingVolumeBlob = await zip.file("trading_volume.png").async("blob");
            setTradingVolumeUrl(URL.createObjectURL(tradingVolumeBlob));
            const bollingerBandsBlob = await zip.file("bollinger_bands.png").async("blob");
            setBollingerBandsUrl(URL.createObjectURL(bollingerBandsBlob));
            const rsiBlob = await zip.file("rsi.png").async("blob");
            setRsiUrl(URL.createObjectURL(rsiBlob));

        } catch (err) {
            setError(`Error during the plot fetch operation: ${err.message}`);
            console.error(err);
        }
    };

    if (authLoading || isSubscriptionStatusLoading) {
        return (
            <Container maxWidth="xs" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
            </Container>
        );
    }

    if (!subscribeStatus) {
        return (
            <Container maxWidth={false}>

                <Grid container spacing={3} alignItems="flex-start" justifyContent="center">
                    <Grid item xs={12} md={3}>
                        <Box sx={{ marginTop: '0px' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '14px' }}>
                                <Card sx={{ boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)', backgroundColor: '#353535', borderRadius: '15px', border: '2px solid #35353500', width: '100%', mt: 2 }}>
                                    <CardContent sx={{ padding: '0px 0px' }}>
                                        <StocksBlogPosts />
                                    </CardContent>
                                </Card>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <PricingBox />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <MoreByNebulaSolver />
                    </Grid>
                </Grid>
                <Helmet>
                    <title>NebulaSolver - Stock Analysis</title>
                    <link rel="canonical" href={`https://nebulasolver.com/stocks`}/>
                    <meta property="og:title" content="NebulaSolver - Stock Analysis"/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png"/>
                    <meta property="og:url" content="https://nebulasolver.com/stocks"/>
                    <meta property="og:description"
                          content="NebulaSolver - Stock Analysis. Generate Stock Performance Plots based off a stock ticker."/>
                    <link rel="alternate" hrefLang="en" href={`https://nebulasolver.com/stocks`}/>
                    <link rel="alternate" hrefLang="x-default" href={`https://nebulasolver.com/stocks`}/>
                    <meta name="twitter:card" content="summary_large_image"/>
                    <meta name="twitter:title" content="NebulaSolver - Stock Analysis"/>
                    <meta name="twitter:description"
                          content="NebulaSolver - Stock Analysis. Generate Stock Performance Plots based off a stock ticker."/>
                    <meta name="twitter:image" content="%PUBLIC_URL%/apple-touch-icon.png"/>
                    <meta name="twitter:site" content="@nebulasolver"/>
                </Helmet>
            </Container>
        );
    }

    return (
        <Container maxWidth={false}>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={3} alignItems="stretch" justifyContent="center">
                    <Grid item xs={12} md={3}>
                        <Box
                            sx={{
                                width: '100%',
                                marginTop: '30px',
                                '& .MuiTextField-root': { mb: 2 },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#1c2734',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#9DC183',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#b9b9b9',
                                    },
                                },
                                '& .MuiInputBase-input': {
                                    color: '#9ca3af',
                                },
                                '& .MuiInputLabel-root': {
                                    color: '#9ca3af',
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: '#9ca3af',
                                },
                            }}
                        >
                            <Card sx={{ ...transferFunctionCardStyle, height: '100%' }}>
                                <CardContent>
                                    <Typography variant="h6" sx={headerTextStyle}>
                                        Stock Ticker
                                    </Typography>

                                    <TextField
                                        label="Stock Ticker"
                                        value={ticker}
                                        onChange={(e) => setTicker(e.target.value)}
                                        fullWidth
                                        margin="normal"
                                        sx={{
                                            mb: 2,
                                            display: 'block',
                                            margin: '0 auto',
                                        }}
                                    />
                                    <DatePicker
                                        label="Start Date"
                                        value={startDate}
                                        onChange={(newValue) => setStartDate(newValue)}
                                        renderInput={(params) => <TextField {...params} fullWidth sx={{ mb: 2 }} />}
                                    />
                                    <DatePicker
                                        label="End Date"
                                        value={endDate}
                                        onChange={(newValue) => setEndDate(newValue)}
                                        renderInput={(params) => <TextField {...params} fullWidth sx={{ mb: 2 }} />}
                                    />
                                    <Button
                                        width="50%"
                                        variant="contained"
                                        sx={{
                                            mt: 1,
                                            backgroundColor: '#15a2fa',
                                            '&:hover': {
                                                backgroundColor: '#9DC183',
                                            },
                                            display: 'block',
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                        }}
                                        onClick={() => handleAnalysis()}>
                                        Submit
                                    </Button>
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6} >
                        <Box sx={{ width: '100%', marginTop: '30px' }}>
                            {stockPriceUrl && (
                                <Card sx={{ ...bodePlotCardStyle, height: '100%'}} elevation={4}>
                                    <CardMedia
                                        component="img"
                                        image={stockPriceUrl}
                                        alt="Stock Price Plot"
                                        title="Stock Price Plot"
                                        sx={bodePlotImageStyle}
                                    />
                                </Card>
                            )}
                            {tradingVolumeUrl && (
                                <Card sx={{ ...bodePlotCardStyle, height: '100%', mt:'25px' }} elevation={4}>
                                    <CardMedia
                                        component="img"
                                        image={tradingVolumeUrl}
                                        alt="Trading Volume Plot"
                                        title="Trading Volume Plot"
                                        sx={bodePlotImageStyle}
                                    />
                                </Card>
                            )}
                            {movingAverageUrl && (
                                <Card sx={{ ...bodePlotCardStyle, height: '100%', mt:'25px' }} elevation={4}>
                                    <CardMedia
                                        component="img"
                                        image={movingAverageUrl}
                                        alt="Moving Average Plot"
                                        title="Moving Average Plot"
                                        sx={bodePlotImageStyle}
                                    />
                                </Card>
                            )}
                            {bollingerBandsUrl && (
                                <Card sx={{ ...bodePlotCardStyle, height: '100%', mt:'25px' }} elevation={4}>
                                    <CardMedia
                                        component="img"
                                        image={bollingerBandsUrl}
                                        alt="Bollinger Bands Plot"
                                        title="Bollinger Bands Plot"
                                        sx={bodePlotImageStyle}
                                    />
                                </Card>
                            )}
                            {rsiUrl && (
                                <Card sx={{ ...bodePlotCardStyle, height: '100%', mt:'25px' }} elevation={4}>
                                    <CardMedia
                                        component="img"
                                        image={rsiUrl}
                                        alt="RSI Plot"
                                        title="RSI Plot"
                                        sx={bodePlotImageStyle}
                                    />
                                </Card>
                            )}
                            {marketCapUrl && (
                                <Card sx={{ ...bodePlotCardStyle, height: '100%', mt:'25px' }} elevation={4}>
                                    <CardMedia
                                        component="img"
                                        image={marketCapUrl}
                                        alt="Market Cap Plot"
                                        title="Market Cap Plot"
                                        sx={bodePlotImageStyle}
                                    />
                                </Card>
                            )}
                            {volatilityUrl && (
                                <Card sx={{ ...bodePlotCardStyle, height: '100%', mt:'25px'}} elevation={4}>
                                    <CardMedia
                                        component="img"
                                        image={volatilityUrl}
                                        alt="Volatility Plot"
                                        title="Volatility Plot"
                                        sx={bodePlotImageStyle}
                                    />
                                </Card>
                            )}
                        </Box>
                    </Grid>


                    <Grid item xs={12} md={3} >
                        <Box sx={{ marginTop: '0px' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    marginTop: '14px',
                                }}
                            >
                                <Card
                                    sx={{
                                        boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                                        backgroundColor: '#353535',
                                        borderRadius: '15px',
                                        border: '2px solid #35353500',
                                        width: '100%',
                                        mt: 2,
                                    }}
                                >

                                    <CardContent sx={{padding:'0px 0px'}}>
                                        <StocksBlogPosts/>
                                    </CardContent>

                                </Card>

                            </Box>

                        </Box>
                    </Grid>
                </Grid>
            </LocalizationProvider>
            <Helmet>
                <title>NebulaSolver - Stock Analysis</title>
                <link rel="canonical" href={`https://nebulasolver.com/stocks`}/>
                <meta property="og:title" content="NebulaSolver - Stock Analysis"/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png"/>
                <meta property="og:url" content="https://nebulasolver.com/stocks"/>
                <meta property="og:description"
                      content="NebulaSolver - Stock Analysis. Generate Stock Performance Plots based off a stock ticker."/>
                <link rel="alternate" hrefLang="en" href={`https://nebulasolver.com/stocks`}/>
                <link rel="alternate" hrefLang="x-default" href={`https://nebulasolver.com/stocks`}/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content="NebulaSolver - Stock Analysis"/>
                <meta name="twitter:description"
                      content="NebulaSolver - Stock Analysis. Generate Stock Performance Plots based off a stock ticker."/>
                <meta name="twitter:image" content="%PUBLIC_URL%/apple-touch-icon.png"/>
                <meta name="twitter:site" content="@nebulasolver"/>
            </Helmet>
        </Container>
    );
};

const cardStyleTransferFunction = {
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#353535',
    borderRadius: '15px',
    border: '2px solid #35353500',
    width: '100%',
    marginBottom: '20px',
};

const cardStyle = {
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#353535',
    borderRadius: '15px',
    border: '2px solid #35353500',
    width: '100%',
    marginBottom: '20px',
    padding:'0px'
};

const bodePlotCardStyle = {
    ...cardStyle,
    margin: 'auto',
};

const bodePlotImageStyle = {
    width: '100%',
    height: 'auto',
    borderRadius:'10px',
};

const transferFunctionCardStyle = {
    ...cardStyleTransferFunction,
    margin: 'auto',
};

const headerTextStyle = {
    textAlign: 'center',
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 'bold',
    letterSpacing: '0.05em',
    background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    animation: `${rainbowAnimation} 10s ease infinite`,
    backgroundSize: '200% 200%',
    '@media (max-width:600px)': {
        fontSize: '1.2em',
    },
    marginBottom: '10px',
};

export default Stocks;
