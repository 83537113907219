/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { Box, Container, Button, Typography, Card, CardContent, CircularProgress, Grid, Link, AppBar, Toolbar, IconButton } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { keyframes } from '@emotion/react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactFlow, {
    ReactFlowProvider,
    addEdge,
    MiniMap,
    Controls,
    Background,
    useNodesState,
    useEdgesState,
    getRectOfNodes,
    getTransformForBounds
} from 'reactflow';
import 'reactflow/dist/style.css';
import { toPng } from 'html-to-image';
import './overview.css';
import AnnotationNode from './AnnotationNode';
import AnnotationNodeUp from './AnnotationNodeUp';
import AnnotationNodeRight from './AnnotationNodeRight';
import AnnotationNodeLeft from './AnnotationNodeLeft';
import ToolbarNode from './ToolbarNode';
import ResizerNode from './ResizerNode';
import CircleNodeLeft from './CircleNodeLeft';
import CircleNodeRight from './CircleNodeRight';
import CircleNodeUp from './CircleNodeUp';
import CircleNodeDown from './CircleNodeDown';
import TextNodeUp from './TextNodeUp';
import TextNodeDown from './TextNodeDown';
import TextNodeLeft from './TextNodeLeft';
import TextNodeRight from './TextNodeRight';
import InSignalNode from './InSignalNode';
import OutSignalNode from './OutSignalNode';
import AndNode from './AndNode';
import OrNode from './OrNode';
import NotNode from './NotNode';
import ButtonEdge from './ButtonEdge';
import DiagramsBlogPosts from "../HowTosDiagrams/DiagramsBlogPosts";
import AnnotationIcon from './commentDown-gray.png';
import AnnotationIconUp from './commentUp-gray.png';
import AnnotationIconRight from './commentRight-gray.png';
import AnnotationIconLeft from './commentLeft-gray.png';
import TextIconDown from './text-down-gray.png';
import TextIconUp from './textUp-gray-v2.png';
import TextIconRight from './textRight-gray-v2.png';
import TextIconLeft from './textLeft-gray-v2.png';
import InSignalIcon from './signal.png';
import OutSignalIcon from './outSignal.png';
import CircleIcon from './circleRight-gray.png';
import CircleIconLeft from './circleLeft-gray.png';
import CircleIconDown from './circleDown-gray.png';
import CircleIconUp from './circleUp-gray.png';
import AndIcon from './and-gray-v2.png';
import OrIcon from './or-gray-v2.png';
import NotIcon from './not-gray-v2.png';
import {FaPlay} from "react-icons/fa";
import { FaDownload } from "react-icons/fa";
import { IoMdDownload } from "react-icons/io";

const rainbowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const hoverAnimation = keyframes`
  0% { color: #fffffe; }
  100% { color: #9DC183; }
`;

const nodeTypes = {
    annotation: AnnotationNode,
    annotationUp: AnnotationNodeUp,
    annotationRight: AnnotationNodeRight,
    annotationLeft: AnnotationNodeLeft,
    tools: ToolbarNode,
    resizer: ResizerNode,
    circleRight: CircleNodeRight,
    circleLeft: CircleNodeLeft,
    circleUp: CircleNodeUp,
    circleDown: CircleNodeDown,
    textinputDown: TextNodeDown,
    textinputUp: TextNodeUp,
    textinputRight: TextNodeRight,
    textinputLeft: TextNodeLeft,
    and: AndNode,
    or: OrNode,
    not: NotNode,
    inSignal: InSignalNode,
    outSignal: OutSignalNode,
};

const edgeTypes = {
    button: ButtonEdge,
};

const nodeClassName = (node) => node.type;

const getId = () => `dndnode_${+new Date()}`;

const SidebarButtons = () => {
    const onDragStart = (event, nodeType) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
    };

    const buttonIcons = [
        { type: 'annotationRight', icon: AnnotationIconRight },
        { type: 'annotation', icon: AnnotationIcon },
        { type: 'annotationLeft', icon: AnnotationIconLeft },
        { type: 'annotationUp', icon: AnnotationIconUp },
        { type: 'circleRight', icon: CircleIcon },
        { type: 'circleDown', icon: CircleIconDown },
        { type: 'circleLeft', icon: CircleIconLeft },
        { type: 'circleUp', icon: CircleIconUp },
        { type: 'textinputRight', icon: TextIconRight },
        { type: 'textinputDown', icon: TextIconDown },
        { type: 'textinputLeft', icon: TextIconLeft },
        { type: 'textinputUp', icon: TextIconUp },
        { type: 'and', icon: AndIcon },
        { type: 'or', icon: OrIcon },
        { type: 'not', icon: NotIcon },
        { type: 'inSignal', icon: InSignalIcon },
        { type: 'outSignal', icon: OutSignalIcon },
    ];

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            {buttonIcons.map(({ type, icon }) => (
                <IconButton
                    key={type}
                    onDragStart={(event) => onDragStart(event, type)}
                    draggable
                    sx={{ color: '#ffc801', mt: 1 }}
                >
                    <img src={icon} alt={`${type} icon`} style={{ width: '48px', height: '48px', objectFit: 'contain' }} />
                </IconButton>
            ))}
        </Box>
    );
};

const PricingBox = () => (
    <Grid>
        <Box
            sx={{
                marginTop: '30px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Card
                sx={{
                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                    backgroundColor: '#353535',
                    borderRadius: '15px',
                    border: '2px solid #35353500',
                    width: '100%',
                }}
            >
                <CardContent>
                    <Typography
                        variant="h6"
                        sx={{
                            textAlign: 'center',
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 'bold',
                            letterSpacing: '0.05em',
                            background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            animation: `${rainbowAnimation} 10s ease infinite`,
                            backgroundSize: '200% 200%',
                            '@media (max-width:600px)': {
                                fontSize: '1.2em',
                            },
                            marginBottom: '10px',
                        }}
                    >
                        Pricing
                    </Typography>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography
                            variant="h4"
                            sx={{ color: '#9DC183', marginBottom: '20px' }}
                        >
                            $0.99 / month
                        </Typography>
                        <Box sx={{ textAlign: 'left', margin: '0 auto', maxWidth: '400px' }}>
                            {[
                                'Full access to all the apps',
                                'Free articles and tutorials',
                                'No need to install anything',
                                'Auth0 Authentication',
                                'Stripe Payments Integration'
                            ].map((item) => (
                                <Typography
                                    key={item}
                                    variant="h6"
                                    sx={{ color: '#b9b9b9', marginBottom: '5px', display: 'flex', alignItems: 'center' }}
                                >
                                    <i className="fas fa-check-circle" style={{ marginRight: '10px', color: '#9DC183' }}></i>
                                    {item}
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Box>
        <Box sx={{ mt: 3, textAlign: 'center' }}>
            <Card sx={{ boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)', borderRadius: '15px', backgroundColor: '#353535' }}>
                <CardContent>
                    <Typography variant="h5" gutterBottom sx={{ color: 'white' }}>
                        Get NebulaSolver
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2, color: '#b9b9b9' }}>
                        Subscribe to access the Diagramming Tool.
                    </Typography>
                    <Button
                        variant="contained"
                        component={RouterLink}
                        to="/subscribe"
                        sx={{
                            backgroundColor: '#15a2fa',
                            '&:hover': {
                                backgroundColor: '#9DC183',
                            },
                            width: '50%',
                            display: 'block',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            animation: `${pulseAnimation} 2s infinite`,
                        }}
                    >
                        Go to Subscribe
                    </Button>
                </CardContent>
            </Card>
        </Box>
    </Grid>
);

const MoreByNebulaSolver = () => (
    <Box sx={{ marginTop: '0px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '14px' }}>
            <Card sx={{ boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)', backgroundColor: '#353535', borderRadius: '15px', border: '2px solid #35353500', width: '100%', mt: 2 }}>
                <CardContent sx={{ padding: '14px 0px' }}>
                    <Typography variant="h6" sx={{ textAlign: 'center', fontFamily: "'Poppins', sans-serif", fontWeight: 'bold', letterSpacing: '0.05em', background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', animation: `${rainbowAnimation} 10s ease infinite`, backgroundSize: '200% 200%', '@media (max-width:600px)': { fontSize: '1.2em' }, marginBottom: '10px' }}>
                        More by NebulaSolver
                    </Typography>
                    <Box sx={{ textAlign: 'left', margin: '0 30px', maxWidth: '400px' }}>
                        {[
                            { href: "https://nebulasolver.com/equations", text: "Equation Solver" },
                            { href: "https://nebulasolver.com/matrix", text: "Linear Algebra" },
                            { href: "https://nebulasolver.com/controlsystems", text: "Control Systems" },
                            { href: "https://nebulasolver.com/finiteelements", text: "Finite Elements" },
                            { href: "https://nebulasolver.com/thermodynamics", text: "Thermodynamics" },
                            { href: "https://nebulasolver.com/batterymanagement", text: "Battery Management" },
                            { href: "https://nebulasolver.com/electronics", text: "Electronics" },
                            { href: "https://nebulasolver.com/systems", text: "Systems Engineering" },
                            { href: "https://nebulasolver.com/diagrams", text: "Diagrams" },
                            { href: "https://nebulasolver.com/gantt", text: "Gantt Chart" },
                            { href: "https://nebulasolver.com/stocks", text: "Stock Analysis" }
                        ].map(item => (
                            <Link
                                key={item.href}
                                href={item.href}
                                sx={{
                                    display: 'block',
                                    mb: 1,
                                    color: '#fffffe',
                                    textDecoration: 'none',
                                    '&:hover': {
                                        animation: `${hoverAnimation} 0.3s forwards`
                                    }
                                }}
                            >
                                <i className="fas fa-angle-right" style={{ marginRight: '10px', color: '#9DC183' }}></i>
                                {item.text}
                            </Link>
                        ))}
                    </Box>
                </CardContent>
            </Card>
        </Box>
    </Box>
);

const Diagrams = () => {
    const { user, isAuthenticated, isLoading: authLoading } = useAuth0();
    const [subscribeStatus, setSubscribeStatus] = useState(false);
    const [isSubscriptionStatusLoading, setIsSubscriptionStatusLoading] = useState(true);

    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const onConnect = useCallback((params) => setEdges((eds) => addEdge({ ...params, animated: true, type: 'smoothstep' }, eds)), [setEdges]);

    const onDrop = useCallback(
        (event) => {
            event.preventDefault();
            const reactFlowBounds = event.target.getBoundingClientRect();
            const type = event.dataTransfer.getData('application/reactflow');

            if (typeof type === 'undefined' || !type) {
                return;
            }

            const position = {
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top,
            };
            const newNode = {
                id: getId(),
                type,
                position,
                data: { label: `${type}` },
            };

            setNodes((nds) => nds.concat(newNode));
        },
        [setNodes]
    );

    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    useEffect(() => {
        const fetchSubscriptionStatus = async () => {
            setIsSubscriptionStatusLoading(true);
            if (isAuthenticated && user && !authLoading) {
                try {
                    const response = await fetch('/.netlify/functions/getUserSubscriptionStatus', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ userEmail: user.email }),
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error ${response.status}`);
                    }

                    const subscriptionData = await response.json();
                    setSubscribeStatus(subscriptionData.isSubscribed);
                } catch (error) {
                    console.error('Error fetching subscription status:', error);
                } finally {
                    setIsSubscriptionStatusLoading(false);
                }
            } else {
                setIsSubscriptionStatusLoading(false);
            }
        };

        fetchSubscriptionStatus();
    }, [user, isAuthenticated, authLoading]);

    const onDownloadClick = useCallback(() => {
        const nodesBounds = getRectOfNodes(nodes);
        const transform = getTransformForBounds(nodesBounds, 1024, 768, 0.5, 2);

        toPng(document.querySelector('.react-flow__viewport'), {
            backgroundColor: 'transparent',
            width: 1024,
            height: 768,
            style: {
                width: 1024,
                height: 768,
                transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
            },
        }).then((dataUrl) => {
            const a = document.createElement('a');
            a.setAttribute('download', 'reactflow.png');
            a.setAttribute('href', dataUrl);
            a.click();
        });
    }, [nodes]);

    if (authLoading || isSubscriptionStatusLoading) {
        return (
            <Container maxWidth={false} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
            </Container>
        );
    }

    if (!subscribeStatus) {
        return (
            <Container maxWidth={false}>
                <Grid container spacing={3} alignItems="flex-start" justifyContent="center">
                    <Grid item xs={12} md={3}>
                        <Box sx={{ marginTop: '0px' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '14px' }}>
                                <Card sx={{ boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)', backgroundColor: '#353535', borderRadius: '15px', border: '2px solid #35353500', width: '100%', mt: 2 }}>
                                    <CardContent sx={{ padding: '0px 0px' }}>
                                        <DiagramsBlogPosts />
                                    </CardContent>
                                </Card>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <PricingBox />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <MoreByNebulaSolver />
                    </Grid>
                </Grid>
                <Helmet>
                    <title>NebulaSolver - Diagrams</title>
                    <link rel="canonical" href={`https://nebulasolver.com/diagrams`} />
                    <meta property="og:title" content="NebulaSolver - Diagrams" />
                    <meta property="og:type" content="website" />
                    <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png" />
                    <meta property="og:url" content="https://nebulasolver.com/diagrams" />
                    <meta property="og:description" content="NebulaSolver - Cloud-Based Diagrams" />
                    <link rel="alternate" hrefLang="en" href={`https://nebulasolver.com/diagrams`} />
                    <link rel="alternate" hrefLang="x-default" href={`https://nebulasolver.com/diagrams`} />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="NebulaSolver - Diagrams" />
                    <meta name="twitter:description" content="NebulaSolver - Cloud-Based Diagrams" />
                    <meta name="twitter:image" content="%PUBLIC_URL%/apple-touch-icon.png" />
                    <meta name="twitter:site" content="@nebulasolver" />
                </Helmet>
            </Container>
        );
    }

    return (
        <Container maxWidth={false}>
            <ReactFlowProvider>
                <AppBar position="relative" sx={{ backgroundColor: '#353535', boxShadow: 'none', padding: '10px 0' }}>
                    <Toolbar sx={{ justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', gap: '20px', marginBottom: '10px', mt: 1 }}>
                            {/*<Button*/}
                            {/*    variant="contained"*/}
                            {/*    onClick={onDownloadClick}*/}
                            {/*    sx={{*/}
                            {/*        backgroundColor: '#15a2fa',*/}
                            {/*        '&:hover': { backgroundColor: '#9DC183' },*/}
                            {/*        animation: `${pulseAnimation} 2s infinite`,*/}
                            {/*        marginTop: '10px',*/}
                            {/*        color: 'white'*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    Download*/}
                            {/*</Button>*/}
                            <Button
                                onClick={onDownloadClick}
                                sx={{ color: '#ffc801' }}
                                startIcon={<FaDownload />}
                            >
                                Download
                            </Button>
                        </Box>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>
                            <SidebarButtons />
                        </Box>
                    </Toolbar>
                </AppBar>
                <Grid container spacing={3} alignItems="stretch" justifyContent="center" sx={{ mt: 2 }}>
                    <Grid item xs={12} md={1}>
                        <Box sx={{ width: '100%', marginTop: '30px', height: '100%', backgroundColor: '#2a2a2a' }}>
                            {/* Placeholder content */}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <Box sx={{ width: '100%', marginTop: '30px', height: '600px' }}>
                            <ReactFlow
                                nodes={nodes}
                                edges={edges}
                                onNodesChange={onNodesChange}
                                onEdgesChange={onEdgesChange}
                                onConnect={onConnect}
                                onDrop={onDrop}
                                onDragOver={onDragOver}
                                fitView
                                attributionPosition="top-right"
                                nodeTypes={nodeTypes}
                                edgeTypes={edgeTypes}
                                className="overview"
                            >
                                <MiniMap zoomable pannable nodeClassName={nodeClassName} />
                                <Controls />
                                <Background />
                            </ReactFlow>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={1}>
                        <Box sx={{ width: '100%', marginTop: '30px', height: '100%', backgroundColor: '#2a2a2a' }}>
                            {/* Placeholder content */}
                        </Box>
                    </Grid>
                </Grid>
            </ReactFlowProvider>
            <Helmet>
                <title>NebulaSolver - Diagrams</title>
                <link rel="canonical" href={`https://nebulasolver.com/diagrams`} />
                <meta property="og:title" content="NebulaSolver - Diagrams" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta property="og:url" content="https://nebulasolver.com/diagrams" />
                <meta property="og:description" content="NebulaSolver - Cloud-Based Diagrams" />
                <link rel="alternate" hrefLang="en" href={`https://nebulasolver.com/diagrams`} />
                <link rel="alternate" hrefLang="x-default" href={`https://nebulasolver.com/diagrams`} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="NebulaSolver - Diagrams" />
                <meta name="twitter:description" content="NebulaSolver - Cloud-Based Diagrams" />
                <meta name="twitter:image" content="%PUBLIC_URL%/apple-touch-icon.png" />
                <meta name="twitter:site" content="@nebulasolver" />
            </Helmet>
        </Container>
    );
};

export default Diagrams;
